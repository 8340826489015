const fetchPaged = async (service, options, filters) => {
  const {
    page, itemsPerPage, sortBy, sortDesc,
  } = options

  const sortOption = {}
  if (sortBy.length) {
    if (sortDesc[0]) sortOption[sortBy[0]] = 'DESC'
    else sortOption[sortBy[0]] = 'ASC'
  }

  const response = await service(JSON.stringify(filters), JSON.stringify(sortOption), itemsPerPage, page)

  if (response.ok) {
    return {
      data: response.data,
      total: response.total,
    }
  }

  return {
    data: [],
    total: 0,
  }
}

export default fetchPaged
