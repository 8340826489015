import axios from 'axios'
import { asyncLoading } from 'vuejs-loading-plugin'

const API_URL_BACKEND = process.env.VUE_APP_API_URL_BACKEND

const exportExcel = async endpoint => {
  const { accessToken } = localStorage
  const servicePromise = axios.get(`${API_URL_BACKEND}v1${endpoint}`, {
    responseType: 'blob',
    headers: {
      'el-token': `Bearer ${accessToken}`,
      'Accept-Language': 'en',
    },
  })

  try {
    asyncLoading(servicePromise).then().catch()
    const [materializedPromise] = await Promise.all([servicePromise])
    const blob = new Blob([materializedPromise.data], { type: 'application/vnd.ms-excel' })
    const filename = materializedPromise.headers['content-disposition'].split('"')[1]
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filename
    link.click()
    URL.revokeObjectURL(link.href)
  } catch (error) {
    console.error(error)
  }

  // .then(response => {
  //   const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
  //   const filename = response.headers['content-disposition'].split('"')[1]
  //   const link = document.createElement('a')
  //   link.href = URL.createObjectURL(blob)
  //   link.download = filename
  //   link.click()
  //   URL.revokeObjectURL(link.href)
  // }).catch(console.error)
}

export default exportExcel
